export const SIGNIN = 'SIGNIN';
export const SIGNUP = 'SIGNUP';
export const ALERT = 'ALERT_BEGIN';
export const ALERT_BEGIN = 'ALERT_BEGIN';
export const ALERT_SUCCESS = 'ALERT_SUCCESS';
export const ALERT_FAIL = 'ALERT_FAIL';

//authentication
export const SIGNUP_BEGIN = 'SIGNUP_BEGIN';
export const SIGNUP_SUCCESS = 'SIGNUP_SUCCESS';
export const SIGNUP_FAIL = 'SIGNUP_FAIL';
export const SIGNIN_BEGIN = 'SIGNIN_BEGIN';
export const SIGNIN_SUCCESS = 'SIGNIN_SUCCESS';
export const SIGNIN_FAIL = 'SIGNIN_FAIL';
export const LOGOUT = 'LOGOUT';
export const SHOW_USER_REQUEST = 'SHOW_USER_REQUEST';
export const SHOW_USER = 'SHOW_USER';
export const SHOW_USER_FAIL = 'SHOW_USER_FAIL';
export const FORGOT_PASSWORD_REQUEST = 'FORGOT_PASSWORD_REQUEST';
export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS';
export const FORGOT_PASSWORD_FAIL = 'FORGOT_PASSWORD_FAIL';
export const RESET_PASSWORD_REQUEST = 'RESET_PASSWORD_REQUEST';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAIL = 'RESET_PASSWORD_FAIL';

//refresh token
export const REFRESH_TOKEN_REQUEST = 'REFRESH_TOKEN_REQUEST';
export const REFRESH_TOKEN_SUCCESS = 'REFRESH_TOKEN_SUCCESS';
export const REFRESH_TOKEN_FAIL = 'REFRESH_TOKEN_FAIL';

//user
export const ADD_ADDRESS_REQUEST = 'ADD_ADDRESS_REQUEST';
export const ADD_ADDRESS_SUCCESS = 'ADD_ADDRESS_SUCCESS';
export const ADD_ADDRESS_FAIL = 'ADD_ADDRESS_FAIL';
export const USER_UPDATE_REQUEST = 'USER_UPDATE_REQUEST';
export const USER_UPDATE_SUCCESS = 'USER_UPDATE_SUCCESS';
export const USER_UPDATE_FAIL = 'USER_UPDATE_FAIL';
export const GET_USER_ADDRESS_REQUEST = 'GET_USER_ADDRESS_REQUEST';
export const GET_USER_ADDRESS_SUCCESS = 'GET_USER_ADDRESS_SUCCESS';
export const GET_USER_ADDRESS_FAIL = 'GET_USER_ADDRESS_FAIL';
export const UPDATE_USER_ADDRESS_REQUEST = 'UPDATE_USER_ADDRESS_REQUEST';
export const UPDATE_USER_ADDRESS_SUCCESS = 'UPDATE_USER_ADDRESS_SUCCESS';
export const UPDATE_USER_ADDRESS_FAIL = 'UPDATE_USER_ADDRESS_FAIL';
export const DELETE_USER_ADDRESS_REQUEST = 'DELETE_USER_ADDRESS_REQUEST';
export const DELETE_USER_ADDRESS_SUCCESS = 'DELETE_USER_ADDRESS_SUCCESS';
export const DELETE_USER_ADDRESS_FAIL = 'DELETE_USER_ADDRESS_FAIL';
export const CHANGE_PASSWORD_REQUEST = 'CHANGE_PASSWORD_REQUEST';
export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS';
export const CHANGE_PASSWORD_FAIL = 'CHANGE_PASSWORD_FAIL';

//favorite
export const ADD_TO_FAVORITE_REQUEST = 'ADD_TO_FAVORITE_REQUEST';
export const ADD_TO_FAVORITE_SUCCESS = 'ADD_TO_FAVORITE_SUCCESS';
export const ADD_TO_FAVORITE_FAIL = 'ADD_TO_FAVORITE_FAIL';
export const GET_USER_FAVORITE_REQUEST = 'GET_USER_FAVORITE_REQUEST';
export const GET_USER_FAVORITE_SUCCESS = 'GET_USER_FAVORITE_SUCCESS';
export const GET_USER_FAVORITE_FAIL = 'GET_USER_FAVORITE_FAIL';
export const REMOVE_FAVORITE_REQUEST = 'REMOVE_FAVORITE_REQUEST';
export const REMOVE_FAVORITE_SUCCESS = 'REMOVE_FAVORITE_SUCCESS';
export const REMOVE_FAVORITE_FAIL = 'REMOVE_FAVORITE_FAIL';

//order
export const ADD_ORDER_REQUEST = 'ADD_ORDER_REQUEST';
export const ADD_ORDER_SUCCESS = 'ADD_ORDER_SUCCESS';
export const ADD_ORDER_FAIL = 'ADD_ORDER_FAIL';
export const CREATE_PAYMENT_INTENT_REQUEST = 'CREATE_PAYMENT_INTENT_REQUEST';
export const CREATE_PAYMENT_INTENT_SUCCESS = 'CREATE_PAYMENT_INTENT_SUCCESS';
export const CREATE_PAYMENT_INTENT_FAIL = 'CREATE_PAYMENT_INTENT_FAIL';
export const ORDER_COMPLETE = 'ORDER_COMPLETE';
export const GET_USER_ORDER_REQUEST = 'GET_USER_ORDER_REQUEST';
export const GET_USER_ORDER_SUCCESS = 'GET_USER_ORDER_SUCCESS';
export const GET_USER_ORDER_FAIL = 'GET_USER_ORDER_FAIL';

//product
export const FETCH_ALL_PRODUCT_REQUEST = 'FETCH_ALL_PRODUCT_REQUEST';
export const FETCH_ALL_PRODUCT = 'FETCH_ALL_PRODUCT';
export const FETCH_ALL_PRODUCT_FAIL = 'FETCH_ALL_PRODUCT_FAIL';
export const FETCH_SINGLE_PRODUCT_REQUEST = 'FETCH_SINGLE_PRODUCT_REQUEST';
export const FETCH_SINGLE_PRODUCT = 'FETCH_SINGLE_PRODUCT';
export const FETCH_SINGLE_PRODUCT_FAIL = 'FETCH_SINGLE_PRODUCT_FAIL';
export const INCREASE = 'INCREASE';
export const DECREASE = 'DECREASE';
export const TOTAL = 'TOTAL';
export const FETCH_PRODUCT_BY_SEARCH_REQUEST =
  'FETCH_PRODUCT_BY_SEARCH_REQUEST';
export const FETCH_PRODUCT_BY_SEARCH = 'FETCH_PRODUCT_BY_SEARCH';
export const FETCH_PRODUCT_BY_SEARCH_FAIL = 'FETCH_PRODUCT_BY_SEARCH_FAIL';
export const FETCH_PRODUCT_BY_CATEGORY_REQUEST =
  'FETCH_PRODUCT_BY_CATEGORY_REQUEST';
export const FETCH_PRODUCT_BY_CATEGORY_SUCCESS =
  'FETCH_PRODUCT_BY_CATEGORY_SUCCESS';
export const FETCH_PRODUCT_BY_CATEGORY_FAIL = 'FETCH_PRODUCT_BY_CATEGORY_FAIL';
export const PRODUCT_ERROR = 'PRODUCT_ERROR';
export const SHOW_MODAL = 'SHOW_MODAL';
export const CLOSE_MODAL = 'CLOSE_MODAL';

//Reviews
export const PRODUCT_REVIEW_REQUEST = 'PRODUCT_REVIEW_REQUEST';
export const PRODUCT_REVIEW_SUCESS = 'PRODUCT_REVIEW_SUCESS';
export const PRODUCT_REVIEW_FAIL = 'PRODUCT_REVIEW_FAIL';
export const CREATE_USER_REVIEW_REQUEST = 'CREATE_USER_REVIEW_REQUEST';
export const CREATE_USER_REVIEW_SUCESS = 'CREATE_USER_REVIEW_SUCESS';
export const CREATE_USER_REVIEW_FAIL = 'CREATE_USER_REVIEW_FAIL';
export const GET_USER_REVIEW_REQUEST = 'GET_USER_REVIEW_REQUEST';
export const GET_USER_REVIEW_SUCCESS = 'GET_USER_REVIEW_SUCCESS';
export const GET_USER_REVIEW_FAIL = 'GET_USER_REVIEW_FAIL';

//category
export const FETCH_CATEGORY_REQUEST = 'FETCH_CATEGORY_REQUEST';
export const FETCH_CATEGORY_SUCCESS = 'FETCH_CATEGORY_SUCCESS';
export const FETCH_CATEGORY_FAIL = 'FETCH_CATEGORY_FAIL';

//cart
export const ADD_TO_CART_REQUEST = 'ADD_TO_CART_REQUEST';
export const ADD_TO_CART = 'ADD_TO_CART';
export const ADD_TO_CART_FAIL = 'ADD_TO_CART_FAIL';
export const LOAD_CART_REQUEST = 'LOAD_CART_REQUEST';
export const LOAD_CART = 'LOAD_CART';
export const LOAD_CART_FAIL = 'LOAD_CART_FAIL';
export const REMOVE_CART_REQUEST = 'REMOVE_CART_REQUEST';
export const REMOVE_CART_SUCCESS = 'REMOVE_CART_SUCCESS';
export const REMOVE_CART_FAIL = 'REMOVE_CART_FAIL';
export const REMOVE_CART = 'REMOVE_CART';
export const GET_TOTAL_CART = 'GET_TOTAL_CART';
