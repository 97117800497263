import React from 'react';
import styled from 'styled-components';

const FrontLoading = () => {
  return (
    <LoadingWrapper>
      <p>Please wait for 30 seconds</p>
      <div className='load-1'>
        <div className='line'></div>
        <div className='line'></div>
        <div className='line'></div>
      </div>
    </LoadingWrapper>
  );
};

const LoadingWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 10rem;

  /* loading */

  .line {
    display: inline-block;
    width: 20px;
    height: 20px;
    border-radius: 15px;
    margin-right: 7px;
    background-color: #4b9cdb;
  }

  .load-1 .line:nth-last-child(1) {
    animation: loadingA 1.5s 1s infinite;
  }
  .load-1 .line:nth-last-child(2) {
    animation: loadingA 1.5s 0.7s infinite;
  }
  .load-1 .line:nth-last-child(3) {
    animation: loadingA 1.5s 0s infinite;
  }
  @keyframes loadingA {
    0% {
      height: 20px;
    }
    50% {
      height: 50px;
    }
    100% {
      height: 20px;
    }
  }
  /* loading */
`;

export default FrontLoading;
